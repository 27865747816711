import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        {
          path: "/games/:game",
          component: () => import("@/view/pages/game/Body.vue"),
          children: [
            {
              path: "tours/:tour/predict",
              component: () => import("@/view/pages/game/Predict.vue")
            },
            {
              path: "tours/:tour/leaderboard",
              component: () => import("@/view/pages/game/Leaderboard.vue")
            },
            {
              path: "tours/:tour/fixtures",
              component: () => import("@/view/pages/game/Fixture.vue")
            },
            {
              path: "tours/:tour/rewards",
              component: () => import("@/view/pages/game/Reward.vue")
            },
            {
              path: "tours/:tour",
              component: () => import("@/view/pages/game/Info.vue")
            },
            {
              path: "",
              component: () => import("@/view/pages/game/Tournament.vue")
            }
          ]
        },
        {
          path: "/matches/:match",
          component: () => import("@/view/pages/match/Info.vue")
        },
        {
          path: "/wallets/:wallet",
          component: () => import("@/view/pages/wallet/Index.vue"),
          meta: {
            type: 'home'
          }
        },
        {
          path: "/wallets/:wallet/predict",
          component: () => import("@/view/pages/wallet/Predict.vue"),
          meta: {
            type: 'predict'
          }
        },
        {
          path: "/wallets/:wallet/fixtures",
          component: () => import("@/view/pages/wallet/Fixture.vue"),
          meta: {
            type: 'fixtures'
          }
        },
        {
          path: "/wallets/:wallet/leaderboard",
          component: () => import("@/view/pages/wallet/Leaderboard.vue"),
          meta: {
            type: 'leaderboard'
          }
        },
        {
          path: "/wallets/:wallet/rewards",
          component: () => import("@/view/pages/wallet/Reward.vue"),
          meta: {
            type: 'rewards'
          }
        },
        {
          path: "/profile",
          component: () => import("@/view/pages/user/Profile.vue")
        }
      ]
    },
    {
      path: "/about",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "userdeletion",
          component: () => import("@/view/pages/about/Userdeletion.vue")
        },
        {
          path: "tos",
          component: () => import("@/view/pages/about/Term.vue")
        },
        {
          path: "privacy",
          component: () => import("@/view/pages/about/Privacy.vue")
        },
        {
          path: "",
          component: () => import("@/view/pages/about/Main.vue")
        }
      ]
    },
    {
      path: "/confirmemail",
      component: () => import("@/view/pages/auth/login_pages/Confirm")
    },
    {
      name: "login",
      path: "/login",
      component: () => import("@/view/pages/auth/login_pages/Login-2")
    },
    {
      name: "register",
      path: "/register",
      component: () => import("@/view/pages/auth/login_pages/Login-2")
    },
    {
      name: "forgot",
      path: "/forgot",
      component: () => import("@/view/pages/auth/login_pages/Login-2")
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
