import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
export const UPDATE_NAME = "updateName";
export const UPDATE_IMG = "updateImage";
export const SHOW_DIALOG = "showDialog";
export const SHOW_MODE = "showMode";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const SET_NAME = "setName";
export const SET_IMG = "setImage"
export const SET_DIALOG = "setDialog"
export const SET_DIALOG_MODE = "setDialogMode"

const state = {
  errors: null,
  user: {},
  show: false,
  mode: 'login',
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  showLogin(state) {
    return state.show
  },
  showMode(state) {
    return state.mode
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("/auth/login", credentials)
        .then(({ data }) => {
          // console.log("Here what post returns", data);
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.failed);
          reject(response)
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("verify")
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
  [UPDATE_NAME](context, name) {
    context.commit(SET_NAME, name)
  },
  [UPDATE_IMG](context, url) {
    context.commit(SET_IMG, url)
  },
  [SHOW_DIALOG](context, s) {
    context.commit(SET_DIALOG, s)
    context.commit(SET_DIALOG_MODE, 'login')
  },
  [SHOW_MODE](context, mode) {
    context.commit(SET_DIALOG, true)
    context.commit(SET_DIALOG_MODE, mode)
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
  [SET_NAME](state, name) {
    if (state.isAuthenticated) {
      state.user.name = name
    }
  },
  [SET_IMG](state, url) {
    if (state.isAuthenticated) {
      state.user.img = url
    }
  },
  [SET_DIALOG](state, s) {
    state.show = s
  },
  [SET_DIALOG_MODE](state, mode) {
    state.mode = mode
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
