// action types
export const UPDATE_PERSONAL_INFO = "updateUserPersonalInfo";
export const UPDATE_ACCOUNT_INFO = "updateUserAccountInfo";
export const UPDATE_WALLET_INFO = "updateWalletInfo";
export const UPDATE_WALLET_POINT = "updateWalletPoint";

// mutation types
export const SET_PERSONAL_INFO = "setPersonalInfo";
export const SET_ACCOUNT_INFO = "setAccountInfo";
export const SET_WALLET_INFO = "setWalletInfo";
export const SET_WALLET_POINT = "setWalletPoint";

const state = {
  user_personal_info: {
    photo: "media/users/300_21.jpg",
    name: "James",
    surname: "Jones",
    company_name: "Fifestudios",
    job: "Application Developer",
    email: "matt@fifestudios.com",
    phone: "44(76)34254578",
    company_site: "fifestudios"
  },
  user_account_info: {
    username: "nick84",
    email: "nick.watson@loop.com",
    language: "English",
    time_zone: "(GMT-11:00) Midway Island",
    communication: {
      email: true,
      sms: true,
      phone: false
    },
    verification: true
  },
  wallet: []
};

const getters = {
  currentUserPersonalInfo(state) {
    return state.user_personal_info;
  },

  currentUserAccountInfo(state) {
    return state.user_account_info;
  },

  currentUserPhoto(state) {
    return state.user_personal_info.photo;
  },

  currentWallet(state) {
    return state.wallet
  }
};

const actions = {
  [UPDATE_PERSONAL_INFO](context, payload) {
    context.commit(SET_PERSONAL_INFO, payload);
  },
  [UPDATE_ACCOUNT_INFO](context, payload) {
    context.commit(SET_ACCOUNT_INFO, payload);
  },
  [UPDATE_WALLET_INFO](context, payload) {
    context.commit(SET_WALLET_INFO, payload);
  },
  [UPDATE_WALLET_POINT](context, payload) {
    context.commit(SET_WALLET_POINT, payload);
  }
};

const mutations = {
  [SET_PERSONAL_INFO](state, user_personal_info) {
    state.user_personal_info = user_personal_info;
  },
  [SET_ACCOUNT_INFO](state, user_account_info) {
    state.user_account_info = user_account_info;
  },
  [SET_WALLET_INFO](state, wallet_info) {
    state.wallet = wallet_info;
  },
  [SET_WALLET_POINT](state, { no, points, time }) {
    var w = state.wallet.find(v => v.no === no)
    if (w != null) {
      if (points != null) {
        w.points = points
      }

      if (time != null) {
        w.time = time
      }
    }
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
