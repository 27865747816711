import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    firebase.initializeApp({
      apiKey: process.env.VUE_APP_FB_APIKEY,
      authDomain: process.env.VUE_APP_FB_AUTHDOMAIN,
      projectId: process.env.VUE_APP_FB_PROJECTID,
      storageBucket: process.env.VUE_APP_FB_STORAGE,
      messagingSenderId: process.env.VUE_APP_FB_MESSAGING,
      appId: process.env.VUE_APP_FB_APPID,
      measurementId: process.env.VUE_APP_FB_MEASUREMENTID
    })

    firebase.analytics()

    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

    Vue.axios.interceptors.request.use(async function (config) {
      config.withCredentials = true
  
      var token = JwtService.getToken()
      if (token != null) {
        config.headers.common["Authorization"] = 'Bearer ' + token
      }
  
      return config
    })
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;
